import { Col, Row } from "antd";

const CloudServersVolumes = () => {
   return (
      <Row>
         <Col>
            Cloud Servers Volumes
         </Col>
      </Row>
   )
}

export default CloudServersVolumes;