import { Col, Row } from "antd"

const CloudServersImages = () => {
   return (
      <Row>
         <Col>
            Cloud Servers Images
         </Col>
      </Row>
   )
}

export default CloudServersImages;