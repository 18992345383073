import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
} from "antd";
import { BiRupee } from "react-icons/bi";
import { FcMoneyTransfer, FcDataProtection } from "react-icons/fc";

//Custom imports
import "./dashboard.scss";

const Dashboard = () => {
  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Dashboard
            </Col>
            <Col span="10" flex="row" align="end"></Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="8">
          <Card className="full-width">
            <Row>
              <Col span="7">
                <div className="dashboard-card-icon">
                  <FcMoneyTransfer />
                </div>
              </Col>
              <Col span="17">
                <div className="dashboard-card-title">
                  <div className="card-big-title">
                    <BiRupee /> 9,99,999.00
                  </div>
                  <div className="card-small-title">Balance</div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span="8">
          <Card className="full-width">
            <Row>
              <Col span="7">
                <div className="dashboard-card-icon">
                  <FcMoneyTransfer />
                </div>
              </Col>
              <Col span="17">
                <div className="dashboard-card-title">
                  <div className="card-big-title">
                    <BiRupee /> 9,99,999.00
                  </div>
                  <div className="card-small-title">Balance</div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span="8">
          <Card className="full-width">
            <Row>
              <Col span="7">
                <div className="dashboard-card-icon">
                  <FcDataProtection />
                </div>
              </Col>
              <Col span="17">
                <div className="dashboard-card-title">
                  <div className="card-big-title">
                    100
                  </div>
                  <div className="card-small-title">Servers in Service</div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
