import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Radio,
  Tooltip,
  Tabs,
  Checkbox,
  Form,
  Select,
  Switch,
  DatePicker,
  Table,
  Modal,
  Tag,
} from 'antd';

import {
  IoAdd,
  IoPencil,
  IoTrash,
  IoInformationCircle,
  IoCheckmarkCircle,
  IoAlertCircle,
} from 'react-icons/io5';
import { FaRupeeSign, FaCloudDownloadAlt } from 'react-icons/fa';


//Custom imports
import './orderDetail.scss';
import { Context } from '../../context';
import { BREADCRUMB } from '../../reducer/types';
import { LoadPage, EditForm } from '../../SharedFunctions';

const InvoiceDetail = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [chasisSKU, setChasisSKU] = useState('');
  const [chasisTableData, setChasisTableData] = useState(null);
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);

  const { state, dispatch } = useContext(Context);
  const { Option } = Select;
  const { TextArea } = Input;

  //Table Columns
  const ordersCols = [
    {
      title: 'Item Description',
      dataIndex: 'itemDescription',
      key: 'itemDescription',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Item Price/Unit',
      dataIndex: 'itemPrice',
      key: 'itemPrice',
      render: (text) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              <FaRupeeSign size="14" color="#777" />
              {text}
            </>
          ) : (
            { text }
          )}
        </>
      ),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Total Tax',
      dataIndex: 'totalTax',
      key: 'totalTax',
      render: (text) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              <FaRupeeSign size="14" color="#777" />
              {text}
            </>
          ) : (
            { text }
          )}
        </>
      ),
    },
    {
      title: 'Sub Total',
      dataIndex: 'subTotal',
      key: 'subTotal',
      className: 'text-end',
      render: (text) => (
        <>
          {text !== 'NA' && text !== 'N/A' ? (
            <>
              <FaRupeeSign size="14" color="#777" />
              {text}
            </>
          ) : (
            { text }
          )}
        </>
      ),
    },
  ];

  //Table Data
  const processorTableData = [
    {
      key: 'id1',
      itemDescription: '1. E5-2430 v3/DDR5/16GB/SSD/960GB',
      quantity: '1',
      itemPrice: '25,000',
      discount: '5000',
      totalTax: '1000',
      subTotal: '21,000',
    },
    {
      key: 'id2',
      itemDescription: '2. ObjectStorage/960GB',
      quantity: '1',
      itemPrice: '5,000',
      discount: '0',
      totalTax: '1000',
      subTotal: '9000',
    },
    {
      key: 'id1',
      itemDescription: '3. CloudServer/SSD/960GB',
      quantity: '1',
      itemPrice: '20,000',
      discount: '5000',
      totalTax: '1000',
      subTotal: '16,000',
    },
  ];

  //Show Order Details
  const goToInvoiceDetail = (id) => {
    LoadPage('InvoiceDetail', dispatch);
  };

  //Show Customer Detail
  const goToCustomerDetail = (id) => {
    LoadPage('customerDetails', dispatch);
  };

  //Handle Cancel
  const handleCancel = () => {
    setUpdateModal(false);
  };

  //Row Data on Edit
  const tableRowEdit = (key) => {
    console.log('Got Row ID - ', key);
    //EditForm(key, "addLocation", dispatch);
    setUpdateModal(true);
  };

  //Common onChange
  const commonOnChange = () => {};

  //Row Data on Delete
  const tableRowDelete = (key) => {
    console.log('Got Row Id, DELETE - ', key);

    //For Demo only Check & Delete
    const updatedTable =
      tableData !== null && tableData.length > 1
        ? tableData.splice(key, 1)
        : [];

    setTableData(updatedTable);
  };

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('manageOrders', dispatch)}
        >
          Manage Orders
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Order Detail</Breadcrumb.Item>
    </Breadcrumb>
  );

  useEffect(() => {
    //Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    //set Table Data
    setTableData(processorTableData);

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Invoice Detail
            </Col>
            <Col span="10" className="text-end">
              <Button type="default" size="large" className="btn-success me-2">
                 <FaCloudDownloadAlt className="me-1" color="#777" /> Download Invoice
              </Button>
              <Button type="primary" size="large" className="btn-success px-3">
                Pay Now &#8377;14750
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24 mb-2">
          <Row span="40">
            <Col span="11">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Company Name:</span>
                    <span className="each-customer-details">
                      IBEE Solutions
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Customer Name:</span>
                    <span className="each-customer-details">S Yugandhar</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width text-truncate">
                    <span className="each-customer-title">Email:</span>
                    <span className="each-customer-details">
                      <Tooltip title="Verified">
                        <IoCheckmarkCircle
                          color="var(--bs-success)"
                          size="18"
                        />
                      </Tooltip>
                      yugandhar.designer@yugandhar.designer.com
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Phone:</span>
                    <span className="each-customer-details">
                      <Tooltip title="Not Verified">
                        <IoAlertCircle color="var(--bs-warning)" size="18" />
                      </Tooltip>
                      +91 12345 12345
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">GST:</span>
                    <span className="each-customer-details">#1234567890</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">
                      KYC Verification:
                    </span>
                    <span className="each-customer-details">
                      <Tooltip title="Verified">
                        <IoCheckmarkCircle
                          color="var(--bs-success)"
                          size="18"
                        />
                      </Tooltip>{' '}
                      Verified
                    </span>
                  </div>
                </li>
              </ul>
            </Col>

            <Col span="9" offset="4">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Nos.:</span>
                    <span className="each-customer-details">#ABCD12345</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Invoice Date:</span>
                    <span className="each-customer-details">
                      18th Nov, 2021
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Payment Status</span>
                    <span className="each-customer-details">
                      <Tag color="red">not paid</Tag>
                    </span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          {/* <Table
            columns={ordersCols}
            dataSource={tableData}
            pagination={false}
          /> */}
          <div className="ant-table">
            <div className="ant-table-container">
              <div className="ant-table-content" style={{ fontSize: '14px' }}>
                <table
                  className="w-100 table-striped"
                  style={{ tableLayout: 'auto' }}
                >
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Item Description</th>
                      <th className="ant-table-cell" width="70">
                        Qty.
                      </th>
                      <th className="ant-table-cell" width="100">
                        Item Price/Unit
                      </th>
                      <th className="ant-table-cell" width="100">
                        Discount
                      </th>
                      <th className="ant-table-cell text-end" width="120">
                        Total Tax
                      </th>
                      <th className="ant-table-cell text-end" width="130">
                        Sub Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell">
                        <strong>1. E5-2430 v3/DDR5/16GB/SSD/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft Windows Standard 2Lic CoreLic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft SQL Server Web Edition 2 Lic Core Lic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">
                        <strong>2. CloudServer/DDR5/16GB/SSD/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell ps-3">
                        Microsoft SQL Server Web Edition 2 Lic Core Lic VL
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 0
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">
                        <strong>3. ObjectStorage/960GB</strong>
                      </td>
                      <td className="ant-table-cell">1</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 12500
                      </td>
                      <td className="ant-table-cell">0</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 2500
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">SubTotal:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">Taxes:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">Discount:</td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 147
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end"></td>
                      <td className="ant-table-cell"></td>
                      <td className="ant-table-cell text-end">
                        <strong>Grand Total:</strong>:
                      </td>
                      <td className="ant-table-cell text-end">
                        <FaRupeeSign size="14" color="#666" /> 14750
                      </td>
                      <td className="ant-table-cell"></td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="text-end">
                        <Button
                          type="primary"
                          size="large"
                          className="btn-success px-3"
                        >
                          Pay Now &#8377;14750
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
        <Col span="24" className="mt-2">
            <div className="full-width mb-2"><strong>Offline payment Bank details</strong></div>
            <div className="full-width">
              Account Name: IBEE SOFTWARE SOLUTIONS PRIVATE LIMITED <br/>
Account No.: 020205001561<br/>
Bank Name: ICICI Bank<br/>
IFSC Code: ICIC0000202<br/>
Branch: Punjagutta, Hyderabad<br/>
            </div>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetail;
