import { Row, Col, Input, Menu, Dropdown, Button } from "antd";
import {IoAdd, IoRefresh, IoKey} from "react-icons/io5"

const CloudServersManage = () => {

   const { Search } = Input;

   const onSearch = (value) => console.log(value);


  return (
    <Row>
      <Col span="24" className="full-width page-header">
        <Row align="middle">
          <Col span="8" className="page-title">
            Cloud Servers - <span>Create & Manage</span>
          </Col>
          <Col span="16" flex="row" align="end">
            <Row gutter="5" align="middle" justify="end" className="pr-2">
              <Col>
                <Button
                  type="default"
                  className="d-inline-flex align-items-center"
                  color="white"
                >
                  <IoKey /> Show Credentials
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  className="d-inline-flex align-items-center"
                  color="white"
                >
                  <IoRefresh /> Refresh
                </Button>
              </Col>
              <Col>
                <Search
                  placeholder="Search..."
                  onSearch={onSearch}
                  style={{ width: "150px" }}
                />
              </Col>
              <Col span="5">
                <Button type="default" className="btn-primary" color="white">
                  <IoAdd /> Create Server
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CloudServersManage;
