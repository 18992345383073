import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Card,
  Select,
  Form,
  Table,
  Radio,
  Checkbox,
  Tooltip,
  Tabs,
  Modal,
} from "antd";
import { HiDocumentReport } from "react-icons/hi";
import {
  AiOutlineEye,
  AiOutlineReload,
  AiOutlineDelete,
  AiOutlineQuestion,
  AiFillQuestionCircle,
  AiOutlineEdit,
  AiOutlineUpload,
  AiOutlineFolder,
  AiFillFolder,
  AiFillCloseCircle,
} from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";

// Custom imports
import "./cloudStorage.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";

const CloudStorage = () => {
  const [showView, setShowView] = useState("list");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addDomainModal, setAddDomainVisible] = useState(false);

  const { state, dispatch } = useContext(Context);
  const { Search, TextArea } = Input;
  const { Option } = Select;
  const { TabPane } = Tabs;

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("profileAccount", dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Groups</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Monthly Pricing Columns
  const pricingCols = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "pricing",
      key: "pricing",
      render: (text) => (
        <>
          <FaRupeeSign size="12" style={{ color: "#666" }} /> {text}
        </>
      ),
    },
  ];

  // Monthly Pricing
  const pricingData = [
    {
      name: "Data Storage /GB",
      pricing: "2",
      key: "ds11",
    },
    {
      name: "Data Transfer /GB",
      pricing: "2",
      key: "ds11",
    },
  ];

  //Cloud Storage Columns
  const storageCols = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Domains",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (id) => (
        <>
          <Tooltip title="Edit">
            <Button
              type="default"
              size="small"
              className="btn-outline-gray"
              onClick={() => editStorage(id)}
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <AiOutlineEdit size="12" />
            </Button>
          </Tooltip>{" "}
          <Tooltip title="Delete">
            <Button
              type="default"
              size="small"
              className="btn-outline-danger"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <AiOutlineDelete size="12" />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  //Cloud Storage Data
  const storageData = [
    {
      name: "AndhraJyothy",
      type: "public",
      domain: "www.andhrajyothi.com",
    },
    {
      name: "Meander",
      type: "private",
      domain: "www.andhrajyothi.com",
    },
  ];

  //Region onChange
  const regionOnChange = (e) => {
    console.log("Region on change - ", e);
  };

  // Create Contact Form On Submit SUCCESS
  const createNewContactSuccess = () => {};

  // Create Form on Submit FAILS
  const createNewContactFailed = () => {};

  // Role Group
  const options = [
    { label: "Technical", value: "technical" },
    { label: "Billing", value: "billing" },
    { label: "Abuse", value: "abuse" },
    { label: "Emergency", value: "emergency" },
  ];

  // Role OnChange
  const roleOnChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  //Edit Storage
  const editStorage = (id) => {
    setShowView("detail");
  };

  //Files Table Columns
  const filesTableCols = [
    {
      title: "Name",
      dataIndex: "name",
      key: "fileTableName",
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "fileTableSize",
    },
    {
      title: "Modified",
      dataIndex: "modified",
      key: "fileTableModified",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "fileTableAction",
    },
  ];

  //General Edit
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //General Edit Public Access type
  const accessTypeOnChange = () => {};

  // Add Domain Columns
  const addDomainCols = [
    {
      title: "Domain",
      key: "domain",
      dataIndex: "domain",
    },
    {
      title: "",
      key: "adddomainActions",
      dataIndex: "adddomainActions",
      render: (id) => (
        <Button type="default" size="small" className="btn-outline-danger">
          <AiOutlineDelete />
        </Button>
      ),
    },
  ];

  //Add Domain Data
  const addDomainData = [
    {
      key: "addom1",
      domain: "www.domainname.com",
    },
    {
      key: "addom2",
      domain: "www.nameofdomain.com",
    },
  ];

  //Add Domain Modal
  const addDomainhandleCancel = () => {
    setAddDomainVisible(false);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      {showView === "list" ? (
        <>
          <Row>
            <Col span="24" className="full-width page-header">
              <Row align="middle">
                <Col span="6" className="page-title">
                  Cloud Storage
                </Col>
                <Col
                  span="18"
                  flex="row"
                  className="d-flex align-items-center justify-content-end"
                >
                  Region:{" "}
                  <Select
                    defaultValue="hyderabad"
                    style={{ width: 120 }}
                    className="me-1"
                    onChange={regionOnChange}
                  >
                    <Option value="hyderabad">Hyderabad</Option>
                    <Option value="mumbai" disabled>
                      Mumbai
                    </Option>
                    <Option value="usa" disabled>
                      USA
                    </Option>
                  </Select>
                  <Button
                    type="default"
                    className="d-inline-flex align-items-center"
                  >
                    <HiDocumentReport
                      size="16"
                      style={{ marginRight: "3px" }}
                    />
                    View report
                  </Button>
                  <Button
                    type="primary"
                    className="ms-1"
                    onClick={() => setShowView("create")}
                  >
                    + Create New
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="py-1 px-2" xs={24}>
              <Row gutter="30">
                <Col span="12">
                  <Card title="Cloud Storages" extra={<Search />}>
                    <Table
                      columns={storageCols}
                      dataSource={storageData}
                      pagination={false}
                      className="border border-gray"
                    />
                  </Card>
                </Col>
                <Col span="12">
                  <Card title="Access credentials">
                    <ul className="list-unstyled full-width list-gap-10">
                      <li>
                        <Row>
                          <Col span="6">API URL:</Col>
                          <Col span="18">
                            <Input
                              readOnly
                              value="https://auth.files.nl01.cloud.servers.com:5000/v3/"
                            />
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">User name:</Col>
                          <Col span="18">1201</Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Tenant name:</Col>
                          <Col span="18">1110</Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Password:</Col>
                          <Col span="18">
                            <Button type="ghost">
                              <AiOutlineEye style={{ marginRight: "3px" }} />{" "}
                              Show
                            </Button>{" "}
                            <Button type="ghost">
                              <AiOutlineReload style={{ marginRight: "3px" }} />{" "}
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col span="24">
                  <div className="full-width">
                    <div className="full-width pb-1 mb-2 border-bottom fs-5">
                      Prices
                    </div>
                    <div className="full-width">
                      No pre-payments. Your account will be charged at the end
                      of month according to the actual usage.
                    </div>
                    <div className="full-width mt-3">
                      <Row>
                        <Col span="8">
                          <Card title="Pricing">
                            <div className="full-width ">
                              <Table
                                columns={pricingCols}
                                dataSource={pricingData}
                                pagination={false}
                                showHeader={false}
                                className="pricingTable"
                              />
                              <div
                                className="full-width text-center"
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                Need more?{" "}
                                <Button type="link" className="pt-1 px-0">
                                  Request Quote
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : null}

      {showView === "create" ? (
        <>
          {/* Create New Groups Block Starts */}
          <Row>
            <Col span="24">
              <Card title="Create New Storage`">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={createNewContactSuccess}
                  onFinishFailed={createNewContactFailed}
                  autoComplete="off"
                >
                  <Row gutter="30">
                    <Col span="12">
                      <ul className="list-unstyled form-list full-width">
                        <li>
                          <Form.Item
                            label="Storage Name"
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter storage Name!",
                              },
                            ]}
                          >
                            <Input className="" />
                          </Form.Item>
                        </li>
                        <li>
                          <Form.Item>
                            <Radio.Group
                              name="radiogroup"
                              defaultValue="public"
                            >
                              <Radio value="public">Public</Radio>
                              <Radio value="private">Private</Radio>
                              <Radio value="private">CDN</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </li>
                        <li>
                          <Form.Item name="enableUndelete">
                            <Checkbox>
                              Enable undelete
                              <Tooltip title="Files once uploaded cannot be deleted">
                                <AiFillQuestionCircle
                                  size="16"
                                  color="#777777"
                                  className="ms-1"
                                />
                              </Tooltip>
                            </Checkbox>
                          </Form.Item>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <div className="full-width pb-3">
                    <Button type="default" onClick={() => setShowView("list")}>
                      Cancel
                    </Button>
                    <Button
                      type="default"
                      //htmlType="submit"
                      onClick={() => setShowView("list")}
                      className="btn-success ms-2 px-3"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>

          {/* Create New Groups Block Ends */}
        </>
      ) : null}

      {showView === "detail" ? (
        <>
          {/* Create New Contact Block Starts */}
          <Row>
            <Col span="24">
              <div className="full-width bg-white">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={createNewContactSuccess}
                  onFinishFailed={createNewContactFailed}
                  autoComplete="off"
                >
                  <Tabs
                    tabBarExtraContent={{
                      left: (
                        <div className="fs-5 tabs-left-title">
                          Cloud Storage - Meander
                        </div>
                      ),
                    }}
                    className="createNewTabs full-width"
                  >
                    <TabPane tab="Files" key="1">
                      <div className="full-width p-2">
                        <div className="full-width mb-1">
                          <Button
                            type="default me-1"
                            className="filesActionBtn"
                          >
                            <AiOutlineReload /> Refresh
                          </Button>
                          <Button
                            type="default me-1"
                            className="filesActionBtn"
                          >
                            <AiOutlineUpload /> Upload Files
                          </Button>
                          <Button
                            type="default me-1"
                            className="filesActionBtn"
                          >
                            <AiOutlineFolder /> Create SubFolder
                          </Button>
                          <Button
                            type="default me-1"
                            className="filesActionBtn"
                          >
                            <AiOutlineDelete /> Delete Selected
                          </Button>
                        </div>
                        <div className="full-width">
                          <Table columns={filesTableCols} />
                        </div>
                      </div>
                    </TabPane>

                    <TabPane tab="General" key="2">
                      <div className="full-width p-2">
                        <ul className="list-unstyled full-width form-list list-data mb-0">
                          <li>
                            <Row>
                              <Col span="8">Access type:</Col>
                              <Col span="16">Private</Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Total size:</Col>
                              <Col span="16">0 bytes</Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Listing allowed:</Col>
                              <Col span="16">No</Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Listing stylesheet:</Col>
                              <Col span="16"></Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Index file:</Col>
                              <Col span="16"></Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Custom error pages:</Col>
                              <Col span="16">No</Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Enable undelete:</Col>
                              <Col span="16">Yes</Col>
                            </Row>
                          </li>
                          <li>
                            <Row>
                              <Col span="8">Versions storage:</Col>
                              <Col span="16">Not defined</Col>
                            </Row>
                          </li>
                        </ul>
                        <div className="full-width mt-2">
                          <Button type="default" onClick={showModal}>
                            Edit
                          </Button>
                        </div>
                        <Modal
                          title="General - Edit"
                          visible={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          width={700}
                          footer={false}
                        >
                          <div className="w-100">
                            <ul className="list-unstyled list-data mb-1">
                              <li>
                                <Row>
                                  <Col span="10">Access type:</Col>
                                  <Col span="14">
                                    <Radio.Group
                                      onChange={accessTypeOnChange}
                                      value="private"
                                    >
                                      <Radio value="private">Private</Radio>
                                      <Radio value="public">Public</Radio>
                                    </Radio.Group>
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col span="10">
                                    Listing allowed:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Checkbox onChange=""></Checkbox>
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col span="10">
                                    Listing stylesheet:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Input />
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col span="10">
                                    Index file:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Input />
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col span="10">
                                    Custom error pages:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Checkbox onChange=""></Checkbox>
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col span="10">
                                    Custom Enable undelete:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Checkbox checked onChange=""></Checkbox>
                                  </Col>
                                </Row>
                              </li>

                              <li>
                                <Row>
                                  <Col span="10">
                                    Versions storage:{" "}
                                    <Tooltip title="some title runs here">
                                      <AiFillQuestionCircle
                                        color="#777777"
                                        size="16"
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span="14">
                                    <Input />
                                  </Col>
                                </Row>
                              </li>
                            </ul>
                            <div className="border-top pt-2">
                              <Button type="default" onClick={handleCancel}>
                                Cancel
                              </Button>
                              <Button
                                type="primary"
                                className="btn-success px-2 ms-1"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </TabPane>

                    <TabPane tab="Domains" key="3">
                      <div className="full-width p-2">
                        <div className="full-width mb-1">
                          <Button
                            type="default me-1"
                            className="filesActionBtn"
                            onClick={() => setAddDomainVisible(true)}
                          >
                            + Add Domain
                          </Button>
                        </div>
                        <div className="full-width">
                          <Table
                            columns={addDomainCols}
                            dataSource={addDomainData}
                            pagination={false}
                          />
                        </div>

                        <Modal
                          title="General - Edit"
                          visible={addDomainModal}
                          onOk={handleOk}
                          onCancel={addDomainhandleCancel}
                          width={700}
                          footer={false}
                        >
                          <div className="w-100">
                            <ul className="list-unstyled list-data mb-1">
                              <li>
                                <Row>
                                  <Col span="10">Domain Name:</Col>
                                  <Col span="14">
                                    <Input />
                                  </Col>
                                </Row>
                              </li>
                            </ul>
                            <div className="border-top pt-2">
                              <Button
                                type="default"
                                onClick={addDomainhandleCancel}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="primary"
                                className="btn-success px-2 ms-1"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </TabPane>
                    <TabPane tab="Synchronization" key="4">
                      <div className="full-width p-2 mb-3 text-center">
                        <div className="full-width text-center">
                          This storage is not currently synchronized with any
                          other storage
                        </div>
                        <div className="full-width text-center mt-1">
                          <Button type="primary" size="large">
                            Enable Synchronization
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </Form>
              </div>
            </Col>
          </Row>

          {/* Create New Contact Block Ends */}
        </>
      ) : null}
    </>
  );
};

export default CloudStorage;
