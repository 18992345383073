import { LOGIN_SUCCESS, LOGOUT_SUCCESS, PAGE, BREADCRUMB } from "./types";

const localUserId = sessionStorage.getItem("userId");
const localToken = sessionStorage.getItem("token");
const localUserName = sessionStorage.getItem("userName");


export const initialState = {
  isAuthenticated: false,
  token: null,
  userId: null,
  userName: null,
  page: "dsManage",
  breadcrumb: null,
};

export const reducer = (state = initialState, action) => {

  switch (action.type) {

    //Login Success
    case LOGIN_SUCCESS:
      sessionStorage.setItem('token', action.payload.token);
      sessionStorage.setItem('userId', action.payload.userId);
      sessionStorage.setItem('userName', action.payload.userName);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
      }

    // Logout Success
    case LOGOUT_SUCCESS:
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('userName');

      return {
        ...state,
        token: null,
        userId: null,
        userName: null,

      }

      //Update Breadcrumb
    case BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload.breadcrumb,
      };

      // Set Page
    case PAGE:
      return {
        ...state,
        page: action.payload.page
      }

    default:
      return {
        ...state
      }
  }
}