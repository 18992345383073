import React, {useEffect, useState, useContext} from 'react';
import { Layout, Menu } from "antd";



// Custom imports
import {Context} from "../../context";
import { PAGE } from "../../reducer/types";
import { LoadPage } from '../../SharedFunctions';

const SideNavigation = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const { SubMenu } = Menu;

  const {state, dispatch} = useContext(Context);

  const loadPage = (key) => {
    dispatch({
      type: PAGE,
      payload: {
        page: key
      }
    })
  }




  return (
    <Sider theme="light" width="100%">
      <Menu
        style={{ width: "100%" }}
        defaultSelectedKeys={["dashboard"]}
        defaultOpenKeys={["dedicatedServers"]}
        mode="inline"
      >
        <Menu.Item
          key="dashboard"
          onClick={() => LoadPage("dashboard", dispatch)}
        >
          Dashboard
        </Menu.Item>
        <SubMenu key="dedicatedServers" title="Dedicated Servers">
          <Menu.ItemGroup key="g1">
            <Menu.Item
              key="dsManage"
              onClick={() => LoadPage("dsManage", dispatch)}
            >
              Manage
            </Menu.Item>
            <Menu.Item
              key="dsOrder"
              onClick={() => LoadPage("dsOrder", dispatch)}
            >
              Order
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu key="cloudServers" title="Cloud Servers">
          <Menu.ItemGroup key="g2">
            <Menu.Item
              key="ccCreateManage"
              onClick={() => LoadPage("ccCreateManage", dispatch)}
            >
              Manage
            </Menu.Item>
            <Menu.Item
              key="ccImages"
              onClick={() => LoadPage("ccImages", dispatch)}
            >
              Images
            </Menu.Item>
            <Menu.Item
              key="ccVolumes"
              onClick={() => LoadPage("ccVolumes", dispatch)}
            >
              Volumes
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="cloudStorage"
          onClick={() => LoadPage("cloudStorage", dispatch)}
        >
          Cloud Storage
        </Menu.Item>
        <Menu.Item key="dns" onClick={() => LoadPage("dns", dispatch)}>
          DNS
        </Menu.Item>
        <Menu.Item
          key="loadBalancers"
          onClick={() => loadPage("loadBalancers")}
        >
          Load Balancers
        </Menu.Item>
        <Menu.Item
          key="firewalls"
          onClick={() => LoadPage("firewalls", dispatch)}
        >
          Firewalls
        </Menu.Item>

        <SubMenu key="networks" title="Networks">
          <Menu.ItemGroup key="g3">
            <Menu.Item
              key="networksDirectConnect"
              onClick={() => LoadPage("networksDirectConnect", dispatch)}
            >
              Direct Connect
            </Menu.Item>
            <Menu.Item
              key="networkL2Segments"
              onClick={() => LoadPage("networkL2Segments", dispatch)}
            >
              L2 Segments
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="privateRacks"
          onClick={() => LoadPage("privateRacks", dispatch)}
        >
          Private Racks
        </Menu.Item>

        <SubMenu key="monitoring" title="Monitoring">
          <Menu.ItemGroup key="g4" onClick={() => LoadPage("g4", dispatch)}>
            <Menu.Item
              key="monitoringHealthchecks"
              onClick={() => LoadPage("monitoringHealthchecks", dispatch)}
            >
              Healthchecks
            </Menu.Item>
            <Menu.Item
              key="monitoringNotificationSettings"
              onClick={() =>
                LoadPage("monitoringNotificationSettings", dispatch)
              }
            >
              Notification Settings
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="reports" title="Reports">
          <Menu.ItemGroup key="g5">
            <Menu.Item
              key="reportsCloudStorage"
              onClick={() => LoadPage("reportsCloudStorage", dispatch)}
            >
              Cloud Storage
            </Menu.Item>
            <Menu.Item
              key="reportsCloudServers"
              onClick={() => LoadPage("reportsCloudServers", dispatch)}
            >
              Cloud Servers
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <Menu.Item
          key="requests"
          onClick={() => LoadPage("requests", dispatch)}
        >
          Requests
        </Menu.Item>
        <Menu.Item
          key="sslCertificates"
          onClick={() => LoadPage("sslCertificates", dispatch)}
        >
          SSL certificates
        </Menu.Item>

        <SubMenu key="profile" title="Profile">
          <Menu.ItemGroup key="g6">
            <Menu.Item
              key="profileAccount"
              onClick={() => LoadPage("profileAccount", dispatch)}
            >
              Account
            </Menu.Item>
            <Menu.Item
              key="profileContacts"
              onClick={() => LoadPage("profileContacts", dispatch)}
            >
              Contacts
            </Menu.Item>
            <Menu.Item
              key="profileGroups"
              onClick={() => LoadPage("profileGroups", dispatch)}
            >
              Groups
            </Menu.Item>
            <Menu.Item
              key="profileSshKeys"
              onClick={() => LoadPage("profileSSHKeys", dispatch)}
            >
              SSH-Keys
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu key="billing" title="Billing">
          <Menu.ItemGroup key="g7">
            <Menu.Item
              key="billingOrders"
              onClick={() => LoadPage("billingOrders", dispatch)}
            >
              Orders
            </Menu.Item>
            <Menu.Item
              key="billingInvoices"
              onClick={() => LoadPage("billingInvoices", dispatch)}
            >
              Invoices
            </Menu.Item>
            <Menu.Item
              key="billingHroupInvoices"
              onClick={() => LoadPage("billingHroupInvoices", dispatch)}
            >
              Group Invoices
            </Menu.Item>
            <Menu.Item
              key="billingAccountStatement"
              onClick={() => LoadPage("billingAccountStatement", dispatch)}
            >
              Account Statement
            </Menu.Item>
            <Menu.Item
              key="billingPaymentDetails"
              onClick={() => LoadPage("billingPaymentDetails", dispatch)}
            >
              Payment Details
            </Menu.Item>
            <Menu.Item
              key="billingLoadAccount"
              onClick={() => LoadPage("billingLoadAccount", dispatch)}
            >
              Load Account
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    </Sider>
  );
};


export default SideNavigation;