import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Radio,
  Tooltip,
  Tabs,
  Checkbox,
  Form,
} from "antd";

import { IoMdFlag, IoMdTrash } from "react-icons/io";
import {
  FaTools,
  FaRegCreditCard,
  FaExclamationTriangle,
} from "react-icons/fa";
import {
  AiOutlineStop,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineClose,
} from "react-icons/ai";

// Custom imports
import "./profileGroups.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";

const ProfileGroups = () => {
  const [showView, setShowView] = useState("list");

  const { state, dispatch } = useContext(Context);
  const { Search, TextArea } = Input;
  const { TabPane } = Tabs;

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("profileAccount", dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Groups</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Contact Form On Submit SUCCESS
  const createNewContactSuccess = () => {};

  // Create Form on Submit FAILS
  const createNewContactFailed = () => {};

  // Role Group
  const options = [
    { label: "Technical", value: "technical" },
    { label: "Billing", value: "billing" },
    { label: "Abuse", value: "abuse" },
    { label: "Emergency", value: "emergency" },
  ];

  // Role OnChange
  const roleOnChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      {showView === "list" ? (
        <>
          {/* Groups List Starts */}
          <Row>
            <Col span="24" className="full-width page-header">
              <Row align="middle">
                <Col span="6" className="page-title ">
                  Profile - <span>Groups</span>
                </Col>
                <Col span="18" flex="row" align="end">
                  <Search
                    placeholder="input search text"
                    onSearch=""
                    style={{ width: 180 }}
                    className="ms-1"
                  />
                  <Button
                    type="primary"
                    className="ms-1"
                    onClick={() => setShowView("create")}
                  >
                    + Create New
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="py-1 px-2" xs={24}>
              <Row align="stretch" gutter="20">
                <Col xs={24} md={12} xxl={8} className="mb-2">
                  <Card title="IBEE" className="profile-card full-width">
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="8">Email:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="8">Phone number:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li className="mt-1">
                        <Row className="w-100">
                          <Col span="6"></Col>
                          <Col
                            span="18"
                            style={{
                              display: "inline-flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                              >
                                <AiOutlineEdit size={17} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                                onClick={() => setShowView("detail")}
                              >
                                <AiOutlineEye size={17} />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col xs={24} md={12} xxl={8} className="mb-2">
                  <Card title="IBEE2" className="profile-card full-width">
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="8">Email:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="8">Phone number:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li className="mt-1">
                        <Row className="w-100">
                          <Col span="6">
                            <Button
                              type="ghost"
                              size="small"
                              className="footer-icon-btn btn-outline-danger ms-1"
                            >
                              <AiOutlineDelete size={17} />
                            </Button>
                          </Col>
                          <Col
                            span="18"
                            style={{
                              display: "inline-flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                              >
                                <AiOutlineEdit size={17} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                                onClick={() => setShowView("detail")}
                              >
                                <AiOutlineEye size={17} />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Groups List Ends */}
        </>
      ) : null}
      {showView === "create" ? (
        <>
          {/* Create New Groups Block Starts */}
          <Row>
            <Col span="24">
              <Card title="Create New Group">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={createNewContactSuccess}
                  onFinishFailed={createNewContactFailed}
                  autoComplete="off"
                >
                  <Row gutter="30">
                    <Col span="12">
                      <ul className="list-unstyled form-list full-width">
                        <li>
                          <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Name!",
                              },
                            ]}
                          >
                            <Input className="" />
                          </Form.Item>
                        </li>
                        <li>
                          <Form.Item label="Email" name="email">
                            <Input type="email" className="" />
                          </Form.Item>
                        </li>
                        <li>
                          <Form.Item
                            label="Phone number"
                            name="phone"
                            extra="E.g.: +1 (234) 567-89-90"
                          >
                            <Input type="tel" className="" />
                          </Form.Item>
                        </li>
                      </ul>
                    </Col>
                    <Col span="12">
                      <ul className="list-unstyled form-list full-width">
                        <li>
                          <Form.Item label="Description" name="desc">
                            <TextArea rows="9" />
                          </Form.Item>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <div className="full-width pb-3">
                    <Button type="default" onClick={() => setShowView("list")}>
                      Cancel
                    </Button>
                    <Button
                      type="default"
                      //htmlType="submit"
                      onClick={() => setShowView("list")}
                      className="btn-success ms-2 px-3"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
          {/* Create New Groups Block Ends */}
        </>
      ) : null}

      {showView === "detail" ? (
        <>
          {/* Groups List Starts */}
          <Row>
            <Col className="p-2" xs={24}>
              <Row align="stretch" gutter="20">
                <Col span="24" className="mb-2">
                  <Card
                    title="IBEE"
                    className="profile-card full-width"
                    extra={
                      <>
                        <Tooltip title="Close">
                          <Button
                            type="link"
                            shape="circle"
                            className="btn-gray p-0"
                            onClick={() => setShowView("list")}
                          >
                            <AiOutlineClose size="17" />
                          </Button>
                        </Tooltip>
                      </>
                    }
                  >
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="6">Email:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Phone number:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Description:</Col>
                          <Col span="18">
                            <div className="full-width">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Odio quas, illum dicta ab fugit adipisci
                              delectus omnis aspernatur eaque magni accusamus
                              maxime? Non at cumque soluta minima repellat quo
                              suscipit?
                            </div>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Groups List Ends */}
        </>
      ) : null}
    </>
  );
};

export default ProfileGroups;
