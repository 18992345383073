import React, {useContext} from 'react';

import {Context} from "./context";
import {PAGE } from "./reducer/types";


export const url = "https://dev-api.ibee.ai/";



export const LoadPage = (val, dispatch) => {
   console.log('Dispatch val - ', val);

     dispatch({
        type: PAGE,
        payload: {
          page: val,
        },
      });

}